import {
  Product,
  ProductContent,
  useCurrency,
  CheckoutContext,
  logger,
} from '@pangaea-holdings/pangaea-checkout'
import { useEffect, useState, useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../../redux/store'
import { useBrandedRoutes } from '../brand/hooks/useBrandedRoutes'
import { selectBrand } from '../brand/selectors'
import { usePageContext } from '../cms/hooks/usePageContext'
import {
  fetchAllProductsCached,
  fetchMultipleProductsCached,
  fetchProductCached,
  fetchProductDetailsCached,
} from './actions'
import { mergeProductWithDetails } from './functions'
import productRoutes from './routes'
import { ProductDetails, ProductWithDetails, ProductPageState } from './types'

import { useIsMountedRef } from 'utils/hooks'

/**
 * This hook will load all products for the selected currency
 * after a certain amount of time
 */
export function usePreloadProducts(timeout = 0) {
  const dispatch = useTypedDispatch()
  const currency = useCurrency()
  const brand = useTypedSelector(selectBrand())
  const { state } = useContext(CheckoutContext)
  const selectedCountry = state.countries.selectedCountryId
  useEffect(() => {
    if (brand) {
      setTimeout(() => {
        console.log('PRELOADING PRODUCTS')
        dispatch(fetchAllProductsCached(currency, brand, selectedCountry))
      }, timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, currency, selectedCountry])
}

export const useMultipleProductsFromDetails = (
  productDetails: ProductDetails[]
): ProductWithDetails[] => {
  const currency = useCurrency()
  const { state } = useContext(CheckoutContext)
  const { t } = useTranslation()
  const [products, setProducts] = useState<ProductWithDetails[]>([])
  const reduxDispatch = useTypedDispatch()
  const brand = useTypedSelector(selectBrand())
  const productIds = productDetails.map((product) => product.id)
  const selectedCountry = state.countries.selectedCountryId

  useEffect(() => {
    if (brand) {
      const fetchProducts = async () => {
        const bundledProducts = await reduxDispatch(
          fetchMultipleProductsCached(
            currency,
            productIds,
            brand,
            selectedCountry
          )
        )

        setProducts(mergeProductWithDetails(bundledProducts, productDetails, t))
      }
      if (productDetails.length && !products.length) {
        fetchProducts()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails, currency, brand, selectedCountry])

  return products
}

export const useProductRoutes = (slug?: string, color?: string): string => {
  let route = ''
  const routes = useBrandedRoutes(productRoutes)

  if (!slug) {
    route = routes.products()
  } else {
    const prodDetails: { slug: string; color?: string } = { slug }
    if (color) {
      prodDetails.color = color
    }
    route = routes.detail(prodDetails)
  }

  return route
}

export type ProductData = {
  product: Product | null
  productDetails: ProductContent | null
}

type MultipleProductsResponse = {
  products: null | Product[]
  productsDetails?: {
    [key: string]: ProductContent
  }
}

export function useMultipleProducts({
  idsOrSlugs,
  withProductDetails = false,
}: {
  idsOrSlugs: string[] | number[]
  withProductDetails: boolean
}): MultipleProductsResponse {
  const currency = useCurrency()
  const dispatch = useTypedDispatch()
  const brand = useTypedSelector(selectBrand())
  const { state } = useContext(CheckoutContext)
  const selectedCountry = state.countries.selectedCountryId
  const isMounted = useIsMountedRef()
  const [products, setProducts] = useState<Product[] | null>(null)
  const [productsDetails, setProductsDetails] = useState<{
    [key: string]: ProductContent
  }>({})
  const normalizedIds = idsOrSlugs.join(',')

  function getBundledProductsIds(products: Product[]) {
    return products
      .reduce(
        (acc, { bundledProducts }) =>
          bundledProducts ? [...acc, ...bundledProducts] : acc,
        []
      )
      .map(({ id }) => id)
  }

  function getBundledProductsDetails(bundledProductsIds: number[]) {
    return bundledProductsIds.map(async (id) => {
      const data = await dispatch(fetchProductDetailsCached(brand, id))
      return {
        id,
        data,
      }
    })
  }

  function collectFulfilledProductsDetails(
    bundledProductsDetails: PromiseSettledResult<{
      id: number
      data: ProductContent | null
    }>[]
  ) {
    return bundledProductsDetails.reduce<{
      [key: string]: ProductContent
    }>(
      (acc, curr) =>
        curr.status === 'fulfilled'
          ? { ...acc, [curr.value.id]: curr.value.data }
          : acc,
      {}
    )
  }

  async function getProducts() {
    let products: Product[] = []

    if (typeof idsOrSlugs[0] === 'number') {
      products = await dispatch(
        fetchMultipleProductsCached(
          currency,
          idsOrSlugs,
          brand,
          selectedCountry
        )
      )
    } else {
      products = await dispatch(
        fetchAllProductsCached(currency, brand, selectedCountry)
      )
      products = products.filter((product) =>
        (idsOrSlugs as string[]).includes(product.slug)
      )
    }

    if (withProductDetails) {
      const bundledProductsIds = getBundledProductsIds(products)
      const productsDetailsPromises =
        getBundledProductsDetails(bundledProductsIds)
      const settledPromises = await Promise.allSettled(productsDetailsPromises)
      const productsDetails = collectFulfilledProductsDetails(settledPromises)
      return { products, productsDetails }
    }

    return { products }
  }

  useEffect(() => {
    getProducts().then(({ products, productsDetails = null }) => {
      if (!isMounted.current) {
        return
      }

      setProducts(products)
      if (productsDetails) {
        setProductsDetails(productsDetails)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalizedIds, currency, brand, selectedCountry])

  const result: MultipleProductsResponse = {
    products,
  }

  if (withProductDetails) {
    result.productsDetails = productsDetails
  }

  return result
}

export const useProduct = (
  initialProduct: Product | undefined,
  initialProductDetails: ProductContent | undefined,
  slug: string
): ProductPageState => {
  const currency = useCurrency()
  const dispatch = useTypedDispatch()
  const brand = useTypedSelector(selectBrand())
  const ctx = usePageContext<ProductPageState>()
  const { state } = useContext(CheckoutContext)
  const selectedCountry = state.countries.selectedCountryId

  useEffect(() => {
    if (initialProduct) {
      ctx.set('product', initialProduct)
    }
    if (initialProductDetails) {
      ctx.set('productDetails', initialProductDetails)
    }
  }, [])

  const getProduct = async () => {
    const product = await dispatch(
      fetchProductCached(slug, currency, brand, selectedCountry)
    )
    if (product) {
      ctx.set('product', product)
    }
  }

  const getProductContent = async () => {
    const productDetails = await dispatch(
      fetchProductDetailsCached(brand, slug)
    )
    if (productDetails) {
      ctx.set('productDetails', productDetails)
    }
  }

  useEffect(() => {
    if (brand && currency && slug) {
      getProduct()
    }
  }, [brand, currency, slug, selectedCountry])

  useEffect(() => {
    if (brand && slug) {
      getProductContent()
    }
  }, [brand, slug])

  return ctx.state
}

export const useProductsMountPerformanceMeasure = (
  products?: Product[],
  componentName?: string
) => {
  const brand = useTypedSelector(selectBrand())
  const traceId = useMemo(() => Math.floor(Math.random() * 100000), [])
  const timer = useRef<ReturnType<typeof setTimeout>>()
  const timeout = 15000
  const productsRef = useRef<Product[] | undefined>(products)

  const logData = {
    brand,
    traceId,
    action: 'productsPerformance',
    component: componentName ?? 'unknown',
  }

  useEffect(() => {
    performance.clearMarks('productsListMounted')
    performance.mark('productsListMounted')
    let timeoutReset = false
    if (timer.current) {
      timeoutReset = true
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      logger.info(`Brand: ${brand}: Products failed to load`, {
        ...logData,
        timeoutReset,
        timeout,
        logType: 'timedOut',
      })
    }, timeout)

    logger.info(`Brand: ${brand}: Products list mounted`, {
      ...logData,
      logType: 'productsListMounted',
      hasProducts: !!productsRef.current,
      productCount: productsRef.current?.length,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      logger.info(`Brand: ${brand}: Products list unmounted`, {
        ...logData,
        logType: 'productsListUnmounted',
        hasProducts: !!productsRef.current,
        productCount: productsRef.current?.length,
      })
    },
    []
  )

  useEffect(() => {
    productsRef.current = products

    if (typeof performance.getEntriesByName !== 'function') {
      return
    }

    // Opera does not support `getEntriesByName`. We're checking that above.
    // eslint-disable-next-line compat/compat
    const currentMark = performance.getEntriesByName('productsLoaded')
    // Only measure time to first render with products.
    if (!products || (currentMark && currentMark.length)) {
      return
    }

    if (timer.current) {
      clearTimeout(timer.current)
    }

    performance.mark('productsLoaded')
    performance.measure(
      'productsLoadedMeasureFromStart',
      undefined,
      'productsLoaded'
    )
    performance.measure(
      'productsLoadedMeasureFromMount',
      'productsListMounted',
      'productsLoaded'
    )

    // O(n) time | O(1) space
    const { fromMount, fromStart } = performance
      .getEntriesByType('measure')
      .reduce<{
        fromStart: PerformanceEntry | undefined
        fromMount: PerformanceEntry | undefined
      }>(
        (acc, curr) => {
          switch (curr.name) {
            case 'productsLoadedMeasureFromStart':
              return {
                ...acc,
                fromStart: curr,
              }
            case 'productsLoadedMeasureFromMount':
              return {
                ...acc,
                fromMount: curr,
              }
            default:
              return acc
          }
        },
        {
          fromStart: undefined,
          fromMount: undefined,
        }
      )

    logger.info(`Brand: ${brand}: Products list loaded`, {
      ...logData,
      logType: 'productListLoaded',
      productCount: products.length,
      timeToLoadFromStart: {
        start: fromStart?.startTime,
        duration: fromStart?.duration,
      },
      timeToLoadFromMount: {
        start: fromMount?.startTime,
        duration: fromMount?.duration,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])
}

export function useProductPerCategory() {
  const currency = useCurrency()
  const dispatch = useTypedDispatch()
  const brand = useTypedSelector(selectBrand())
  const { state } = useContext(CheckoutContext)
  const isMounted = useIsMountedRef()

  const [products, setProducts] = useState<Product[] | null>(null)

  const selectedCountry = state.countries.selectedCountryId

  async function getProducts() {
    const allProducts = await dispatch(
      fetchAllProductsCached(currency, brand, selectedCountry)
    )

    return { allProducts }
  }

  useEffect(() => {
    getProducts().then(({ allProducts }) => {
      if (!isMounted.current) {
        return
      }

      setProducts(allProducts)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, brand, selectedCountry])

  const result = [] as Product[][]
  const bundles = [] as Product[]
  const NonBundles = [] as Product[]

  products?.map((product) => {
    if (product.bundledProducts) {
      bundles.push(product)
    } else {
      product.categories?.map((category) => {
        if (!result[category]) {
          result[category] = []
        }

        result[category].push(product)
      })

      NonBundles.push(product)
    }
  })

  return {
    products: NonBundles,
    productsCategory: result,
    productsBundle: bundles,
  }
}
