import { useContext } from 'react'

import { CMSPageContext } from '../context/Provider'
import { CMSPageBaseState, CMSContextValue } from '../types'

export function usePageContext<T extends CMSPageBaseState>() {
  const state = useContext(CMSPageContext)

  return state as CMSContextValue<T>
}
