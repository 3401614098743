import { Product, ProductContent } from '@pangaea-holdings/pangaea-checkout'

import { AppState } from '../../redux/rootReducer'
import { getProductsKey, getProductDetailsKey } from './reducer'

export const selectAllProducts =
  (currency: string, brand: string, country?: string) => (state: AppState) => {
    return state.products.all[getProductsKey(brand, currency, country)] as
      | Product[]
      | undefined
  }

export const selectBundleProducts =
  (currency: string, brand?: string, country?: string) => (state: AppState) => {
    if (!brand) {
      return undefined
    }

    const products = state.products.all[
      getProductsKey(brand, currency, country)
    ] as Product[] | undefined

    if (!products) {
      return products
    }

    return products.filter((prod) => !!prod.bundledProducts)
  }

export const selectNonBundleProducts =
  (currency: string, brand?: string, country?: string) => (state: AppState) => {
    if (!brand) {
      return undefined
    }

    const products = state.products.all[
      getProductsKey(brand, currency, country)
    ] as Product[] | undefined

    if (!products) {
      return products
    }

    return products.filter((prod) => !prod.bundledProducts)
  }

export const selectSingleProductDetails =
  (brand: string, idOrSlug: number | string) => (state: AppState) => {
    return state.products.details[getProductDetailsKey(brand, idOrSlug)] as
      | ProductContent
      | undefined
  }

type FindSingleArgs =
  | { id: number; currency: string; brand: string; country?: string }
  | { slug: string; currency: string; brand: string; country?: string }

/**
 * Returns:
 * null if the products are loaded but can find by slug or id
 * undefined if the products are NOT loaded for that currency
 * Product if found
 */
export const selectSingleProduct =
  (opts: FindSingleArgs) => (state: AppState) => {
    if (!state.products) {
      return undefined
    }
    const key = getProductsKey(opts.brand, opts.currency, opts.country)

    const productsArr = state.products.all[key] as Product[] | undefined

    if (productsArr) {
      if ('slug' in opts) {
        return productsArr.find((a) => a.slug === opts.slug) ?? null
      }
      if ('id' in opts) {
        return productsArr.find((a) => a.id === opts.id) ?? null
      }
    }

    const singleArr = state.products.single[key]
    if (singleArr) {
      if ('slug' in opts) {
        return singleArr.find((a) => a.slug === opts.slug) ?? undefined
      }
      if ('id' in opts) {
        return singleArr.find((a) => a.id === opts.id) ?? undefined
      }
    }

    return undefined
  }

export const selectMultipleProducts =
  (currency: string, brand: string, ids: number[], country?: string) =>
  (state: AppState) => {
    const products = ids
      .map((id) => selectSingleProduct({ currency, id, brand })(state))
      .filter((a) => a)

    if (products.length === ids.length) {
      return products as Product[]
    }
    return undefined
  }
