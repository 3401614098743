import { ProductInfo } from '@pangaea-holdings/pangaea-account'
import {
  Product,
  SizeOption,
  AddCartItemBody,
  CustomizationOptionValue,
  CartResponseItem,
} from '@pangaea-holdings/pangaea-checkout'
import { TFunction } from 'i18next'

import { formatCurrencyInteger } from '../../utils/currency'
import { calculateDiscountPercentage } from '../../utils/functions'
import {
  SUBSCRIPTION_INTERVAL,
} from './constants/productDetails'
import {
  ProductDetails,
  ProductWithDetails,
  ProductCategory,
  CategoryId,
  HomePageProductConfig,
} from './types'

export function getProductCustomization(
  product: Product,
): AddCartItemBody {
  const sizes = getVisibleSizes(product)
  const customizationOptions = Object.values(
    product.customizationOptions
  ).reduce<number[]>((selectedOptions, opt) => {
    selectedOptions.push(Object.values(opt.options)[0].id)
    return selectedOptions
  }, [])

  return {
    productId: product.id,
    productSizeOptionId: sizes[0].id,
    productCustomizationOptionIds: customizationOptions,
  }
}

export function getVisibleSizes(product: Product) {
  return Object.values(product.sizes.options).filter((a) => a.isVisible)
}

export function getCheapestVisibleSize(product: Product): SizeOption | null {
  const opts = getVisibleSizes(product)
    .sort((a, b) => a.price - b.price)

  return opts[0] ? opts[0] : null
}

export function getOneTimePurchasePrice(product: Product): number | null {
  const opts = Object.values(product.sizes.options)
    .filter((a) => a.isVisible)
    .sort((a, b) => b.price - a.price)

  return opts[0] ? opts[0].price : null
}

export function getProductPrice(product: Product): number | null {
  return getOneTimePurchasePrice(product)
}

export const getSubscriptionProductPrice = (product: Product, quantity = 1) => {
  const size = getCheapestVisibleSize(product)
  const twoMonthSubscriptionPrice = size ? getSubscriptionMonthsPrice(size) : 0
  return twoMonthSubscriptionPrice * quantity
}

export const prepareAccountSystemProducts = (
  products: Product[]
): ProductInfo[] => {
  let productsInfo: ProductInfo[] = []
  const renewableProducts = products.filter((prod) => prod.isRenewable)
  productsInfo = renewableProducts.map((prod) => {
    const prodInfo: ProductInfo = { ...prod }
    prodInfo.accountSystemAssets = {
      imageUrl: prod.primaryImageUrl,
      desc: prod.description || '',
    }
    return prodInfo
  })
  return productsInfo
}

export const getSubscriptionOptionId = (product: Product): number | null => {
  const opts = Object.values(product.sizes.options)
    .filter((a) => a.isVisible)
    .sort((a, b) => a.price - b.price)

  return opts[0] ? opts[0].id : null
}

export function getRetailValue(products: Product[]): number | null {
  let noRetailValue = false
  const retailValue = products.reduce((prevValue, currProd) => {
    const otp = getOneTimePurchasePrice(currProd)
    noRetailValue = otp === null // We can't get OTP for one of the bundled products
    return prevValue + (otp || 0)
  }, 0)

  return noRetailValue ? null : retailValue // Better not to display anything if we can't get the full retail value
}

export function getSubscriptionMonthsPrice(size: SizeOption): number {
  return size.subscriptions[SUBSCRIPTION_INTERVAL]?.price || size.price
}

/**
 * Filter the product categories for the selected id.
 * @returns The category that matches the id or the first category if no match is found
 */
export function getProductCategory(
  allProductCategories: ProductCategory[],
  categoryId: CategoryId
): ProductCategory | null {
  return (
    allProductCategories.find((category) => category.id === categoryId) || null
  )
}

export const mergeProductWithDetails = (
  products: Product[],
  productDetails: ProductDetails[],
  t: TFunction
): ProductWithDetails[] => {
  const productsWithDetails: ProductWithDetails[] = []
  productDetails.forEach((detail) => {
    const product = products.find((prod) => prod.id === detail.id)
    if (product) {
      product.title = getTranslatedProductTitle(product, t)
      productsWithDetails.push({ ...detail, ...product })
    }
  })
  return productsWithDetails
}

export const getHomePageProducts = (
  productConfig: { [id: number]: HomePageProductConfig },
  productDetails: ProductDetails[]
) => {
  const productIds = Object.keys(productConfig).map(Number)

  return productDetails.filter((productDetail) => {
    return productIds.indexOf(productDetail.id) >= 0
  })
}

export const getDefaultIntervalCount = (
  product: Product,
  subscriptionInterval = SUBSCRIPTION_INTERVAL
): number | undefined => {
  const size = getCheapestVisibleSize(product)
  return size
    ? size.subscriptions[subscriptionInterval]?.interval_count
    : undefined
}

export const getSelectedCustomizationIds = (
  product: Product,
  selectedOptions: string[],
  item?: CartResponseItem
) => {
  return item?.id
    ? getSelectedColorAndOptionIdFromCart(item)
    : (getSelectedColorAndOptionId(product, selectedOptions))
}

export const getSelectedColorAndOptionId = (
  product: Product,
  selectedOptions: string[]
) => {
  const customizationOptions = product.customizationOptions
  const customizationValues = Object.values(customizationOptions).map(
    (option) => {
      return getSelectedOptionOrDefault(
        selectedOptions,
        Object.values(option.options)
      )
    }
  )
  const sizeOptionId = parseInt(Object.keys(product.sizes.options)[1], 10)

  return [...customizationValues, sizeOptionId]
}

const getSelectedOptionOrDefault = (
  selectedOptions: string[],
  options: CustomizationOptionValue[]
): number => {
  selectedOptions = selectedOptions.map((i) => i.toUpperCase())
  const match = options.find((opt) =>
    selectedOptions.includes(opt.text.toUpperCase())
  )
  if (match) {
    return match.id
  } else {
    return options[0].id
  }
}

export const getSelectedColorAndOptionIdFromCart = (
  cartItem: CartResponseItem
) => {
  const selectedCustomizationOptions = cartItem.selectedCustomizationOptions
  const customizationValues = Object.values(selectedCustomizationOptions).map(
    (option) => {
      return getSelectedOptionOrDefault([], [option])
    }
  )
  const sizeOptionId = cartItem.selectedSize.id
  return [...customizationValues, sizeOptionId]
}

export const getProductPriceAndDiscount = (
  bundle: Product | null,
  fractionDigits = 2
) => {
  let price: string = '0'
  let size: SizeOption | null
  let compareAtPrice: string | undefined
  let discountPercent = 0
  let savings = '0'

  if (bundle) {
    size = getCheapestVisibleSize(bundle)
    const compareAtPriceValue = size?.compareAtPrice ?? 0
    compareAtPrice = compareAtPriceValue
      ? formatCurrencyInteger(
          compareAtPriceValue,
          bundle.currency,
          fractionDigits
        )
      : undefined
    const productPrice = getProductPrice(bundle) as number
    price = formatCurrencyInteger(productPrice, bundle.currency, fractionDigits)
    discountPercent = calculateDiscountPercentage(
      compareAtPriceValue,
      productPrice
    )
    savings = formatCurrencyInteger(
      (compareAtPriceValue || productPrice) - productPrice,
      bundle.currency,
      fractionDigits
    )
  }

  return {
    compareAtPrice,
    price,
    discountPercent,
    savings,
  }
}

export const getTranslatedProductTitle = (
  product: Product,
  t: TFunction
): any => t(`products:${product.slug}.name`, product.title)
