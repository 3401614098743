import {
  Product,
  productsApi,
  SubscriptionProductResponse,
  getGeoIpCountryCode,
  ProductContent,
  logger,
} from '@pangaea-holdings/pangaea-checkout'

import { ThunkActionCreator } from '../../redux/rootReducer'
import { AppActionCreator } from '../../redux/store'
import {
  selectAllProducts,
  selectMultipleProducts,
  selectSingleProduct,
  selectSingleProductDetails,
} from './selectors'

import { cleanObject, withRetry } from 'utils/functions'

// Requests tend to get stacked on initial render. We use this map to keep
// in-flight requests so we only make one no matter how many times the function
// is called before the request finishes.
const fetchAllProductsCachedFlights: Record<string, Promise<Product[]>> = {}

export const fetchAllProductsCached: ThunkActionCreator<Promise<Product[]>> =
  (currency: string, brand: string, country?: string) =>
  async (dispatch, getState) => {
    const countryCode = country || (await getGeoIpCountryCode())
    const products = selectAllProducts(
      currency,
      brand,
      countryCode || undefined
    )(getState())
    const getAllProducts = withRetry(
      productsApi.getAllProducts.bind(productsApi),
      { logger }
    )
    const logContext = {
      action: 'fetchAllProductsCached',
      currency,
      brand,
      countryCode,
    }

    if (products) {
      logger.info(
        `Brand ${brand}: cache hit on all products ${currency} ${countryCode}`,
        {
          ...logContext,
          logType: 'cacheHit',
        }
      )
      return products
    }

    logger.info(
      `Brand ${brand}: cache miss on all products ${currency} ${countryCode}`,
      {
        ...logContext,
        logType: 'cacheMiss',
      }
    )

    const inFlightKey = `${brand}:${currency}${
      countryCode ? `:${countryCode}` : ''
    }`

    const inFlight = fetchAllProductsCachedFlights[inFlightKey]

    if (inFlight) {
      logger.info(
        `Brand ${brand}: request in flight ${currency} ${countryCode}`,
        {
          ...logContext,
          logType: 'inFlight',
        }
      )
      return inFlight
    }

    return (fetchAllProductsCachedFlights[inFlightKey] = (async () => {
      const start = Date.now()
      const fetchedProducts = await getAllProducts(
        currency,
        undefined,
        countryCode || undefined
      )

      const delta = Date.now() - start
      logger.info(`Fetching products took ${delta}ms`, {
        ...logContext,
        logType: 'requestFinished',
        delta,
        productCount: fetchedProducts.length,
        sample: fetchedProducts.slice(0, 3),
      })

      dispatch(loadAllProducts(
        fetchedProducts,
        currency,
        brand,
        countryCode || undefined,
      ))

      delete fetchAllProductsCachedFlights[inFlightKey]
      return fetchedProducts
    })())
  }

export const loadAllProducts: AppActionCreator = (
  products: Product[],
  currency: string,
  brand: string,
  country?: string,
) => ({
  type: 'PRODUCTS_LOAD_ALL' as const,
  payload: {
    products,
    currency,
    brand,
    country: country,
  },
})

export const fetchMultipleProductsCached: ThunkActionCreator<
  Promise<Product[]>
> =
  (currency: string, productIds: number[], brand: string, country?: string) =>
  async (dispatch, getState) => {
    const countryCode = country || (await getGeoIpCountryCode())
    const products = selectMultipleProducts(
      currency,
      brand,
      productIds,
      countryCode || undefined
    )(getState())
    if (products) {
      console.log(`Brand ${brand}: cache hit on multiple products ${currency}`)
      return products
    }

    console.log(`Brand ${brand}: cache miss on multiple products ${currency}`)

    const fetchedProducts = await productsApi.getAllProducts(
      currency,
      productIds,
      countryCode || undefined
    )

    dispatch({
      type: 'PRODUCTS_LOAD_MULTIPLE' as const,
      payload: {
        products: fetchedProducts,
        currency,
        brand,
        country: countryCode || undefined,
      },
    })
    // always return in right order
    return productIds.map((id) => {
      return fetchedProducts.find((product) => product.id === id) as Product
    })
  }

export const fetchProductCached: ThunkActionCreator<Promise<Product | null>> =
  (
    slugOrId: string | number,
    currency: string,
    brand: string,
    country?: string
  ) =>
  async (dispatch, getState) => {
    const countryCode = country || (await getGeoIpCountryCode())
    let product: Product | null | undefined
    if (typeof slugOrId === 'string') {
      product = selectSingleProduct({
        slug: slugOrId,
        currency,
        brand,
        country: countryCode || undefined,
      })(getState())
    } else {
      product = selectSingleProduct({ id: slugOrId, currency, brand })(
        getState()
      )
    }

    if (product != null) {
      console.log(
        `Brand ${brand}: cache hit on single products ${slugOrId} ${currency}`
      )
      return product
    }

    console.log(
      `Brand ${brand}: cache miss on single products ${slugOrId} ${currency}`
    )

    const fetchedProduct = await productsApi.getSingleProduct(
      slugOrId,
      currency,
      countryCode || undefined
    )

    dispatch(
      loadSingleProduct(
        currency,
        fetchedProduct,
        brand,
        countryCode || undefined
      )
    )
    return fetchedProduct
  }

export const fetchProductDetailsCached: ThunkActionCreator<
  Promise<ProductContent | null>
> = (brand: string, slugOrId: string | number) => async (dispatch, getState) => {
  const productContent = selectSingleProductDetails(brand, slugOrId)(getState())

  if (productContent) {
    console.log(
      `Brand ${brand}: cache hit on details of single product ${slugOrId}`
    )
    return productContent
  }

  console.log(
    `Brand ${brand}: cache miss on details of single product ${slugOrId}`
  )

  let fetchedProductDetails = await productsApi.getProductDetails(slugOrId)
  fetchedProductDetails = cleanObject(fetchedProductDetails) as ProductContent

  dispatch(loadSingleProductDetails(fetchedProductDetails, brand, slugOrId))
  return fetchedProductDetails
}

export const fetchSubscriptionProducts =
  (ids: number[], currency: string) =>
  async (): Promise<SubscriptionProductResponse> => {
    const fetchedProducts = await productsApi.fetchSubscriptionProducts(
      currency,
      ids
    )
    return fetchedProducts as unknown as SubscriptionProductResponse
  }

export const loadSingleProduct: AppActionCreator = (
  currency: string,
  product: Product,
  brand: string,
  country?: string
) => ({
  type: 'PRODUCTS_LOAD_SINGLE' as const,
  payload: {
    product,
    currency,
    brand,
    country,
  },
})

export const loadSingleProductDetails: AppActionCreator = (
  productContent: ProductContent,
  brand: string,
  idOrSlug: string | number
) => ({
  type: 'PRODUCTS_LOAD_SINGLE_DETAILS' as const,
  payload: {
    productContent,
    idOrSlug,
    brand,
  },
})
